import handleError from 'common/utils/handleError';
import { pickBy } from 'lodash';
import { TrackedUser, TrackerEvent } from '../UserTracker';
import { TrackerClient } from '../UserTracker/interfaces/TrackerInterface';
import { AnyDict } from '../types';
import { UserComTrackerEvent } from './types';

const adjustTextToLengthLimit = (text: string) => text?.slice(0, 40);

export class UserComTrackerClient implements TrackerClient {
  private readonly hostName = 'https://heyspace.user.com/api/public';
  private user: TrackedUser;

  constructor(private apiKey: string) {}

  trackUserEvents = (events: TrackerEvent[]) => {
    try {
      if (!this.apiKey || !this.user) {
        return;
      }

      const userComEvents = events.map(this.parseEventFormat);
      userComEvents.forEach(this.sendUserEvent);
    } catch (e) {
      handleError(e);
    }
  };

  private parseEventFormat = ({
    name,
    epoch,
    properties,
    userRole,
    subscription,
  }: TrackerEvent): UserComTrackerEvent => {
    const rawData = {
      ...properties,
      userRole,
      subscriptionStatus: subscription?.status,
      planName: subscription?.planCode,
    };

    const data = pickBy(rawData, (v) => v !== undefined);

    return {
      name: name,
      timestamp: epoch / 1_000,
      data,
    };
  };

  private sendCreateOrUpdateUser = () => {
    try {
      this.sendEvent(this.updateUserUrl, this.generateUserData());
    } catch (e) {
      handleError(e);
    }
  };

  private sendUserEvent = (event: UserComTrackerEvent) => {
    this.sendEvent(this.postUserEventUrl, event);
  };

  private sendEvent = (url: string, data: AnyDict) => {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${this.apiKey}`,
        Accept: '*/*;version=2',
      },
    });
  };

  private generateUserData = () => {
    const { createdAt, email, firstName, id, lastName, phoneNumber } = this.user;
    return {
      custom_id: id,
      email,
      first_name: adjustTextToLengthLimit(firstName),
      last_name: adjustTextToLengthLimit(lastName),
      last_seen: new Date().getTime(),
      created_at: createdAt,
      phone_number: phoneNumber,
    };
  };

  setUser(user: TrackedUser): void {
    this.user = user;
    this.sendCreateOrUpdateUser();
  }

  private get postUserEventUrl() {
    return `${this.hostName}/users-by-id/${this.user.id}/events/`;
  }

  private get updateUserUrl() {
    return `${this.hostName}/users/update_or_create/`;
  }
}
